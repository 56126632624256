import "./index.scss";

import picFiles from "./assets/pics/*.jpg"
import section1 from "./assets/spring1/*.png";
import section2 from "./assets/spring2/*.png";
import section3 from "./assets/spring3/*.png";

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function randint(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}



function scaleUI() {

    let ratioW = window.innerWidth / 1100;
    let ratioH = window.innerHeight / 650;
    let ratio = ratioW < ratioH ? ratioW : ratioH;

    console.log(ratio)

    if (stage.offsetWidth > window.innerWidth || stage.offsetHeight > window.innerHeight) {
        stage.style.transform = `translate(-50%, -50%) scale(${ratio-0.05})`;
    } else {
        stage.style.transform = `translate(-50%, -50%)`;
    }
}

let stage = document.querySelector(".stage");

scaleUI();
window.addEventListener("resize", scaleUI);

let pics = [];
for (let key in picFiles) {
    pics.push(picFiles[key]);
}




let bgm = document.querySelector("#bgm");
let endAudio = document.querySelector("#endAudio");
let startButton = document.querySelector(".start");
startButton.addEventListener("click", e => {

    bgm.play();
    startButton.style.pointerEvents = "none";

    window.setTimeout(() => {
        startButton.innerText = "SPECIFY QUERY";
        startButton.style.background = "#ff5b32";
    }, 800)

    window.setTimeout(() => {
        startButton.innerText = "UNDERSTOOD STARTING PROGRAM";
        startButton.style.background = "#25b33d";
    }, 2750)

    window.setTimeout(tick, 5400)

    window.setTimeout(() => {
        document.querySelector(".loader").classList.add("loader--hidden");
    }, 5500)

    window.setTimeout(() => {
        startButton.innerText = "I LOVE YOU CODY ♥";
        startButton.style.background = "#be2e2e";
        endAudio.play();
        document.querySelector(".loader").classList.remove("loader--hidden");
    },52000)



})

let backgrounds = [
    document.querySelector("#bg-0"),
    document.querySelector("#bg-1"),
    document.querySelector("#bg-2"),
    document.querySelector("#bg-3"),
    document.querySelector("#bg-4"),
    document.querySelector("#bg-5")
]

let picsLayer = document.querySelector("#pics");

let layers = []

let baseVelocity = 3;
let multiplier = 1;


for (let i = 0; i < backgrounds.length; i++) {
    let bg = backgrounds[i];

    let div1 = document.createElement("div");
    div1.style.backgroundImage = `url(${section1[i]})`;
    bg.appendChild(div1)

    let div2 = document.createElement("div");
    div2.style.backgroundImage = `url(${section2[i]})`;
    bg.appendChild(div2)

    let div3 = document.createElement("div");
    div3.style.backgroundImage = `url(${section3[i]})`;
    bg.appendChild(div3)

    let velocity = baseVelocity * multiplier;

    layers.push(
        [
            [div1, 0, velocity],
            [div2, 995, velocity],
            [div3, 1990, velocity]
        ]
    )

    multiplier *= 0.75;

}

shuffleArray(pics);

let left = 200;

for (let i=0; i<20; i++) {
    let image = new Image();
    image.onload = () => {
        let imgDiv = document.createElement("div");
        let rotate = randint(-10,10);
        imgDiv.style.top = `${randint(100,120)}px`;
        imgDiv.style.transform = `translateX(${left}px) rotate(${rotate}deg)`;
        imgDiv.style.height = `${image.height}px`;
        console.log(pics[i])
        imgDiv.style.backgroundImage = `url(${pics[i]})`;
        picsLayer.appendChild(imgDiv);
        left += 700
    }

    image.src = pics[i];

  
}


let loops = 0;
let picVelocity = baseVelocity * 0.66;
let picTransform = 0;


function tick() {


    for (let i = 0; i < layers.length; i++) {
        let layer = layers[i];

        for (let j = 0; j < 3; j++) {
            let section = layer[j];
            section[1] -= section[2];
            section[0].style.transform = `translateX(${Math.floor(section[1])}px`;


            if (section[1] < -1000) {
                if (i==0) { loops++; console.log(loops) }
                section[1] = 1000;
            }

        }
        // // layers[i].style.transform = `translateX(${Math.floor(transforms[i][0])}px`;

        // layers[i].querySelectorAll("div").forEach(section => {
        //     section.style.transform = `translateX(${Math.floor(transforms[i][0])}px`;

        // })


    }

    picTransform -= picVelocity;
    picsLayer.style.transform = `translateX(${Math.floor(picTransform)}px`;

    window.requestAnimationFrame(tick)

}



// window.setTimeout(tick, 1000)

